// Generated by Framer (70e732d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {JPvofE2wn: {hover: true}};

const cycleOrder = ["JPvofE2wn"];

const variantClassNames = {JPvofE2wn: "framer-v-rdh9v3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "JPvofE2wn", image: gi5P_JMhC = {src: new URL("assets/512/Ru2g4EFctisHnwQFCgOjAYPuCI4.png", import.meta.url).href, srcSet: `${new URL("assets/512/Ru2g4EFctisHnwQFCgOjAYPuCI4.png", import.meta.url).href} 512w, ${new URL("assets/Ru2g4EFctisHnwQFCgOjAYPuCI4.png", import.meta.url).href} 548w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "JPvofE2wn", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-CsvFR", classNames)} style={{display: "contents"}}>
<Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 104, intrinsicWidth: 274, pixelHeight: 208, pixelWidth: 548, sizes: "137px", ...toResponsiveImage(gi5P_JMhC)}} className={cx("framer-rdh9v3", className)} data-framer-name={"Logo"} layoutDependency={layoutDependency} layoutId={"JPvofE2wn"} ref={ref} style={{opacity: 0.5, ...style}} transition={transition} variants={{"JPvofE2wn-hover": {opacity: 1}}} {...addPropertyOverrides({"JPvofE2wn-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-CsvFR [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-CsvFR .framer-1sk29dq { display: block; }", ".framer-CsvFR .framer-rdh9v3 { height: 52px; overflow: visible; position: relative; width: 137px; }", ".framer-CsvFR .framer-v-rdh9v3 .framer-rdh9v3 { cursor: pointer; }", ".framer-CsvFR.framer-v-rdh9v3.hover .framer-rdh9v3 { aspect-ratio: 2.6346153846153846 / 1; height: var(--framer-aspect-ratio-supported, 52px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 52
 * @framerIntrinsicWidth 137
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Ry73ChsDK":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"gi5P_JMhC":"image"}
 */
const FramerYysAHArwg: React.ComponentType<Props> = withCSS(Component, css, "framer-CsvFR") as typeof Component;
export default FramerYysAHArwg;

FramerYysAHArwg.displayName = "Logo";

FramerYysAHArwg.defaultProps = {height: 52, width: 137};

addPropertyControls(FramerYysAHArwg, {gi5P_JMhC: {__defaultAssetReference: "data:framer/asset-reference,Ru2g4EFctisHnwQFCgOjAYPuCI4.png?originalFilename=image.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerYysAHArwg, [])